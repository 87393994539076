// branding configuration
$publication: 'dailyrecord';
$logo-dimensions: 325, 160;
$primary-color: #ED002D;
$header-additional: #BE0024;

$brand-hue: 349;
$brand-saturation: 100%;

$brand-color: hsl($brand-hue, $brand-saturation, 46%);
$highlight: hsl($brand-hue, $brand-saturation, 71%);
$hint: hsl($brand-hue, $brand-saturation, 96%);
$lowlight: hsl($brand-hue, $brand-saturation, 11%);

// overrides
@mixin overrides() {
  .social-sites {
    @include social-icons(true);

    .icon {
      @include icons-background($white, $primary-color);
    }

  }

  .hotjobs .poweredby {
    background-image: url('/@trinitymirrordigital/chameleon-partnership-widgets/madgex/img/scot-career-logo.png');
  }
}
